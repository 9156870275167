<template>
  <div>
    <br>
    <Head
      :keyword="query.keyword"
      :is_visible="query.is_visible"
      @handleQuery="handleQuery"
      @handleRefresh="handleRefresh" />
    <Table
      :tableData="pageData.data"
      :editable="editable"
      :removal="removal"
      @handleDelete="handleDelete"
      @handleRefresh="handleRefresh" />
    <el-dialog
      title="刪除提示"
      :visible.sync="centerDialogVisible"
      width="30%"
      center>
      <span>是否确认删除？</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="centerDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="checkDelete">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { forIn, has } from 'lodash'
import resStatus from '@/constants/resStatus'
import { settingLotteryHotList, settingLotteryDelete } from '@/api/admin/setting'

import Head from './Head'
import Table from './Table'

export default {
  components: {
    Head,
    Table
  },
  data () {
    return {
      centerDialogVisible: false,
      query: {
        page: 1,
        pageSize: 10,
        type: 'hot',
        keyword: '',
        is_visible: ''
      },
      editable: true,
      removal: true,
      pageData: {
        data: []
      },
      isEditable: false,
      deleteId: ''
    }
  },
  methods: {
    init () {
      const pageName = this.$route.name
      switch (pageName) {
        case 'AdminMarketSettingLotteryHotAdd':
        case 'AdminMarketSettingLotteryHotEdit':
          this.isEditable = true
          break
        default:
          this.isEditable = false
          settingLotteryHotList(this.query).then(res => {
            switch (res.status) {
              case resStatus.OK:
                this.pageData = res
                break
              default:
                this.$message.error(res.message)
            }
          }).catch(err => {
            this.$message.error(err)
          })
          break
      }
    },
    handleQuery (obj) {
      forIn(obj, (val, key) => {
        if (has(this.query, key)) {
          this.query[key] = val
        }
      })
    },
    handleEditable (boo) {
      this.editable = boo
    },
    handleDelete (id) {
      this.centerDialogVisible = true
      this.deleteId = id
    },
    checkDelete () {
      this.centerDialogVisible = false
      settingLotteryDelete(this.deleteId, 'hot').then(res => {
        switch (res.status) {
          case resStatus.OK:
            this.handleRefresh()
            break
          default:
            this.$message.error(res.message)
        }
      }).catch(err => {
        this.$message.error(err)
      })
    },
    handleRefresh () {
      this.init()
    }
  },
  created () {
    this.init()
  },
  watch: {
    $route (to, from) {
      this.init()
    }
  }
}
</script>
